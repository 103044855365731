<template>
  <div v-if="basket == null || basket.length == 0">
    <div class="container">
      <div class="background-icon">
        <i class="fa-solid fa-box-open"></i>
      </div>
      <h2 class="text-align-center margin-bottom-30">Indkøbskurven er tom</h2>
      <p class="text-align-center margin-bottom-30">Tilføj forsikringer til din indkøbskurv for at fortsætte.</p>
      <div class="button-flex-container justify-center">
        <router-link class="button" to="/">Tilføj forsikringer</router-link>
      </div>
    </div>
  </div>
  <div v-else-if="basket != null && basket.length > 0">
    <div class="container">
      <h1 class="page-headline">Indkøbskurv</h1>
      <div class="row">
        <div class="column seven">
          <basket-product
            v-for="(item, index) in _basket"
            :key="index"
            :item="item"
            :index="index"
            v-on:editPolicy="editPolicy"
            v-on:deletePolicy="deletePolicy"
          />

          <!-- Collect discount -->
          <message
            :message="collectDiscountMessage"
            :type="collectDiscount ? 'success' : 'info'"
            :icon="collectDiscount ? 'fa-solid fa-circle-star' : null"
            v-on:formFunction="formFunction"
          />

          <div class="button-flex-container margin-bottom-20">
            <router-link class="button white" to="/">Tilføj flere forsikringer</router-link>
          </div>
        </div>
        <div class="column five">
          <div class="box">
            <h4 class="margin-bottom-10">Betalingsoversigt</h4>
            <p class="font-size-12">Tryk på de tilføjede forsikringer for at udstille afgifterne.</p>

            <price-table
              :basket="basket"
              :price="price"
              :collectDiscount="collectDiscount"
              :coupon="coupon"
            />

            <button class="button block-button large-padding" v-on:click="$router.push({ name: 'Betaling' })" :disabled="basket == null || basket.length == 0">
              Gennemfør bestilling
            </button><br />
            <button class="button block-button large-padding yellow" v-on:click="$router.push({ path: '/kontakt' })" :disabled="basket == null || basket.length == 0">
              Book rådgivning
            </button>
          </div>

          <div v-if="showDiscountInput" class="box">
            <h4 class="margin-bottom-10"><i class="fa-light fa-tag margin-right-10"></i>Rabatkode</h4>
            <p class="font-size-12">Rabatten gives på policens præmie ekskl. afgifter. Du kan maksimalt bruge én rabatkode pr. ordre.</p>
            <form @submit.prevent="addCoupon(couponCode)">
              <div class="form-group margin-top-10">
                <div class="input-group" v-if="!coupon">
                  <input
                    v-model="couponCode"
                    v-on:input="couponError = null"
                    :disabled="coupon"
                    type="text"
                    class="form-control"
                    minlength="2"
                    maxlength="20"
                    required
                  />
                  <button class="button white" :disabled="coupon" type="submit">Anvend</button>
                </div>
                <div class="input-group" v-else-if="coupon">
                  <input
                    :value="coupon"
                    :disabled="true"
                    type="text"
                    class="form-control"
                    required
                  />
                  <button class="button white" type="button" v-on:click="removeCoupon()">Fjern</button>
                </div>
                <transition name="hideFormGroup">
                  <div v-if="couponError" class="form-error">
                    <span class="error-text">{{ couponError }}</span>
                  </div>
                </transition>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Product footer -->
    <product-footer
      class="full-width"
      altTitle="Vores kunder siger"
      :showButtons="false"
      :showTrustpilot="false"
    >
      <trustpilot-widget biz-id="48c16bbd000064000503381b" type="carousel" style="margin-top: 50px;" />
    </product-footer>
  </div>
</template>

<script>
import basketProduct from "@/components/basket-product.vue";
import message from "@/components/message.vue";
import PriceTable from "@/components/price-table/price-table.vue";
import ProductFooter from "@/components/product-footer.vue";
import TrustpilotWidget from "@/components/TrustpilotWidget.vue";

export default {
  name: "Kurv",
  components: {
    basketProduct,
    message,
    PriceTable,
    ProductFooter,
    TrustpilotWidget,
  },
  data() {
    return {
      price: null,
      couponCode: null,
      couponError: null,
      showDiscountInput: false,
      reduction: {},
    };
  },
  computed: {
    _basket() {
      const self = this;
      let r = [];
      let i = 0;

      self.reduction = {};

      for (const product of this.$store.state.basket) {
        product.index = i++;
        r.push(product);

        if ('extra' in product) {
          for (const key in product.extra) {
            const extra = product.extra[key];

            if (extra.chosen) {
              r.push({
                hideFactSheet: true,
                noEdit: true,
                index: null,
                productLink: extra.productLink,
                basketName: extra.value,
                price: product.extraPrices[key],
                coverages: {},
                icon: extra.icon,
                productId: extra.product.productCode,
                productName: extra.value,
                objectCode: extra.product.objectCode,
                altEdit: (item, index) => {
                  self.editPolicy(product, index, `extra-${key}`);
                },
                altDelete: () => {
                  extra.chosen = false;
                  self.recalculateBasket();
                },
              });
            }
          }
        }

        // Add extra packages
        for (const [ k, p ] of Object.entries(product.packages)) {
          self.clog("test packs", k, p);
          const sk = `${p.productCode}-${k}`;
          const n = self.reduction[sk] || 0; 

          if ("extra" in p && p.extra && !(p.singleton && n >= 1)) {
            self.clog("extra pack found", p);

            r.push({
                hideFactSheet: true,
                noEdit: true,
                noDelete: true,
                index: null,
                productLink: "https://www.aura.dk/ladeboks-til-din-elbil/ElbilPlus/?gclid=EAIaIQobChMIlO7m7P2u-AIV5QCiAx3rogy3EAAYASAAEgL-CfD_BwE",
                conditionsLink: "https://www.aura.dk/ladeboks-til-din-elbil/ElbilPlus/vilkaar-og-betingelser/",
                basketName: p.title,
                price: p.price,
                coverages: {},
                icon: p.logo,
                productId: p.productCode,
                productName: p.title,
                objectCode: p.objectCode,
                details: p.details,
                product: product,
              });

            self.reduction[sk] = sk in self.reduction ? self.reduction[sk] + 1 :  1;
          }
        }
      }

      return r;
    },
    /*
     * Coupon from vuex store
     */
    basket() {
      return this.$store.state.basket;
    },
    /*
     * Coupon from vuex store
     */
    coupon() {
      return this.$store.state.coupon;
    },
    /*
     * Age from products
     */
    age() {
      let age = null;

      this.basket.forEach((element) => {
        if (element.fields.age.value) {
          age = element.fields.age.value;
        }
      });

      return age;
    },
    /*
     * Number of collect discount products
     */
    collectDiscountNumber() {
      /*let number = 0;

      // Count every collect discount product
      this.basket.forEach((element) => {
        if (element.enableCollectDiscount) {
          number++;
        }
      });

      return number;*/

      return this.price ? this.price.collectDiscountCount : 0;
    },
    /*
     * Boolean for setting collect discount
     */
    collectDiscount() {
      return this.collectDiscountNumber > 2;
    },
    /*
     * Collect discount message object
     */
    collectDiscountMessage() {
      let message = new Object();

      if (this.collectDiscount) {
        message.headline = "Du har opnået helkunderabat!";
        message.text = "Som helkunde får du 10% i samlerabat på vores hus-, sommerhus-, indbo-, ulykke- og bilforsikring, samt mange andre fordele, hvis du som kunde har mindst tre af disse forsikringer. Rabatten gives på policens præmie ekskl. afgifter. <a href='https://aros-forsikring.dk/forsikringer/helkunde/' target='_blank'>Læs mere her</a>.";
      } else {
        message.headline = `Tilføj ${3 - this.collectDiscountNumber} rabatgivende ${ this.collectDiscountNumber == 2 ? 'forsikring' : 'forsikringer' } mere for at opnå helkunderabat!`
        message.text = "Som helkunde får du 10% i samlerabat på vores hus-, sommerhus-, indbo-, ulykke- og bilforsikring, samt mange andre fordele, hvis du som kunde har mindst tre af disse forsikringer. Rabatten gives på policens præmie ekskl. afgifter. Når du bestiller dine forsikringer gennem vores webshop, vil du efterfølgende blive kontaktet til en forsikringsgennemgang, som giver dig mulighed for at samle dine forsikringer. <a href='https://aros-forsikring.dk/forsikringer/helkunde/' target='_blank'>Læs mere her</a>.";
      }

      return message;
    },
  },
  async created() {
    this.recalculateBasket();

    // Any dicount codes?
    try {
      this.showDiscountInput = await this.$api.vouchers.any.read();
    } catch (err) {
      //
    }
  },
  mounted() {
    this.clog({ desc: this.getProductDescription(this.basket) });
  },
  methods: {
    /*
     * Add coupon
     */
    addCoupon(couponCode) {
      let self = this;

      // Create api string
      let apiString = `/api/vouchers/isValid`;

      self
        .axios({
          method: "get",
          url: apiString,
          params: {
            voucher: couponCode,
            preventCache: self.dayjs().unix(),
          },
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then(function (response) {
          // If voucher exist, add to store and recalculate, otherwise show error
          if (response.data.isValid) {
            self.$store.commit("addCoupon", response.data.voucherName);
            self.couponCode = null;
            self.recalculateBasket();
          } else {
            self.couponError = "Vi kunne ikke finde denne rabatkode i vores system";
            self.couponCode = null;
          }
        })
        .catch(function () {
          self.couponError = "Vi kunne ikke finde denne rabatkode i vores system";
          self.couponCode = null;
        });
    },
    /*
     * Remove coupon
     */
    removeCoupon() {
      this.$store.commit("removeCoupon");
      this.recalculateBasket();
    },
    /*
     * Show details
     */
    showDetails(index) {
      this.$refs[`basketItem${index}`][0].classList.toggle("showDetails");
    },
    /*
     * Edit policy
     */
    editPolicy(policy, index, id) {
      this.$router.push({
        hash: `#${id}`,
        name: policy.productName,
        params: {
          policy: {
            value: policy,
            key: index,
          },
        },
      });
    },
    /*
     * Edit policy
     */
    deletePolicy(index) {
      this.$store.commit("removeProductFromIndex", index);
      this.recalculateBasket();
    },
    /*
     * Parse data to api
     */
    parseSubmitData() {
      let self = this;
      let reduction = {};

      // Create Submit data object with standard baseInfo
      let submitData = {
        carObjectData: [],
        homeObjectData: [],
        personalAccidentObjectData: [],
        buildingObjectData: [],
        caravanObjectData: [],
        dogObjectData: [],
        travelObjectData: [],
        baseInfo: {
          age: self.age,
          voucher: self.coupon,
          collectDiscount: self.collectDiscount,
        },
      };

      // Loop through packages
      // eslint-disable-next-line no-unused-vars
      self.basket.forEach((element, index) => {
        const pkey = index;
        const pval = element;

        // Do not parse?
        if ("parse" in element && !element.parse) {
          submitData[element.priceObject].push({ ...element, key: index });
          return;
        }

        // Create car object for the array in submitData
        let currentObject = {
          key: index,
          coverages: "coveragesBase" in element.package ? this.$options.filters.renderTextInObject(Array.from(element.package.coveragesBase), element) : [],
        };

        // Run through fields and fill out data
        for (const [key, value] of Object.entries(element.fields)) {
          // Only send if the field is set to export
          if (value.export) {
            currentObject[key] = value.value;
          }

          // If base info set to true, export to baseInfo
          if (value.baseInfo) {
            submitData.baseInfo[key] = value.value;
          }
        }

        // Run through coverages and put in car object
        element.package.coverages.forEach((coverage) => {
          // Only send if package is included and set to export
          if (coverage.included && coverage.export) {
            // Create coverage element
            let currentCoverage = {
              bundleCode: coverage.key,
              excessId: element.fields.excessId.value,
            };

            // Inject values
            if ("inject" in coverage) {
              for (const key in coverage.inject) {
                currentCoverage[key] = element.fields[coverage.inject[key]].value;
              }
            }

            // Push this coverage to car object
            currentObject.coverages.push(currentCoverage);
          }
        });

        // Run through extra coverages and put in coverages
        if (element.coverages) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(element.coverages)) {
            const keys = Array.isArray(value.keys) ? value.keys : value.keys[pval.package.price.key];

            // Only send if coverage is chosen
            if (value.chosen) {
              // Run through keys on extra coverage
              keys.forEach((number) => {
                // Create coverage element
                let currentCoverage = {
                  bundleCode: number,
                  excessId: element.fields.excessId.value,
                };

                // Inject values
                if ("inject" in value) {
                  for (const key in value.inject) {
                    currentCoverage[key] = element.fields[value.inject[key]].value;
                  }
                }

                // Push this coverage to car object
                currentObject.coverages.push(currentCoverage);
              });
            }
          }
        }

        // Map if priceobject is 'personalAccidentObjectData'
        if (element.priceObject == "personalAccidentObjectData") {
          currentObject.coverages = currentObject.coverages.map((cov) => {
            if ([200, 250].includes(parseInt(cov.bundleCode))) {
              cov.bundleSumInsured = parseInt(currentObject.bundleSumInsured);
            }

            if ([100].includes(parseInt(cov.bundleCode))) {
              cov.bundleSumInsured = parseInt(currentObject.deathSumInsured);
            }

            return cov;
          });
        }

        // Push to submit data
        submitData[element.priceObject].push(currentObject);

        // Add extra products
        if ("extra" in element) {
          for (const key in element.extra) {
            const extra = element.extra[key];

            if (extra.chosen) {
              let prod = {...extra.product, key: `extra_${key}`};

              // imports from main product
              if ("import" in extra) {
                for (const imp of extra.import) {
                  prod[imp] = currentObject[imp];
                }
              }

              // Add prod
              submitData[element.priceObject].push(prod);
            }
          }
        }

        // Add extra packages
        for (const [k, p] of Object.entries(element.packages)) {
          const sk = `${element.productCode}-${k}`;
          const n = reduction[sk] || 0; 

          if (p.extra && !(p.singleton && n >= 1)) {
            submitData[element.priceObject].push({ ...p, key: k });
            reduction[sk] = sk in reduction ? reduction[sk] + 1 :  1;
          }
        }
      });

      return submitData;
    },
    /*
     * Event when you choose a package
     */
    recalculateBasket() {
      let self = this;

      // Set loading to true
      self.$store.commit("loadingTrue");

      // Get submit data without coverages
      let submitData = self.parseSubmitData();

      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then(function (response) {
          // Insert price into objects
          self.insertPrice(response);

          // Set response to price variable
          self.price = response.data;

          // Set loading to false
          self.$store.commit("loadingFalse");

          // Track data for retargeting
          self.trackData("visitBasket", self.$store.state.temporaryProduct, self.basket);
        });
    },
    /*
     * Set from price response
     */
    insertPrice(response) {
      let self = this;

      // Check if the response has objects
      if (response.data.objects && response.data.objects.length > 0) {
        // Run through coverages and put in car object
        response.data.objects.forEach((element) => {
          // Ignore extra products here
          if ("key" in element && element.key.indexOf("extra_") == 0) return;

          // Set price to element
          self.$store.commit("updateProductPrice", element);
        });

        // Update basket for local storage
        self.$store.commit("addBasketToLocalStorage");
      }
    },
    /*
     * Toggle price list
     */
    togglePriceList(index) {
      this.$refs[`subPrices${index}`][0].classList.toggle("show");
    },
  },
};
</script>
