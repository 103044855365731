<template>
  <div class="pricing-table-product">
    <div class="pricing-table-product-header clickable" @click="click">
      <span>
        <span>{{ getProductName(product) }}</span>
        <span v-if="!product.hidePriceDetails" class="pricing-table-product-info">
          <template v-if="!showContent"><i class="fa-light fa-chevron-down"></i>Vis detaljer</template>
          <template v-else><i class="fa-light fa-chevron-up"></i>Skjul detaljer</template>
        </span>
      </span>
      <span v-if="!showContent">
        <span>{{ product.price ? numFormat(product.price.totalMonthlyPrice) : 'ERROR' }}</span>
        <span class="pricing-table-product-info text-align-right">kr./md.</span>
      </span>
    </div>
    <div v-if="showContent" class="pricing-table-product-content">
      <div class="pricing-table-product-content-item">
        <span>Præmie uden afgifter</span>
        <span>{{ product.price ? numFormat(product.price.totalMonthlyRawPrice) : 'ERROR' }}</span>
      </div>
      <template v-for="(coverageItem, indexItem) in _coverages">
        <div v-if="coverageItem.bundleCode != 920 && coverageItem.bundleCode != 904" :key="indexItem" class="pricing-table-product-content-item" >
          <span class="clickable-text text-color" @click="showModal(coverageItem.text, coverageItem.text)">{{ coverageItem.text }}</span>
          <span>{{ numFormat(coverageItem.monthlyPrice) }}</span>
        </div>
      </template>
      <div class="pricing-table-product-content-item" >
        <span class="clickable-text text-color" @click="showModal('Terminstillæg', 'Terminstillæg')">Terminstillæg</span>
        <span>{{ product.price ? numFormat(product.price.totalFeeMonthly) : 'ERROR' }}</span>
      </div>
      <div class="pricing-table-product-content-item total-price">
        <span>Total</span>
        <span>
          <span class="text-align-right">{{ product.price ? numFormat(product.price.totalMonthlyPrice) : 'ERROR' }}</span>
          <span class="pricing-table-product-info text-align-right">kr./md.</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceTableProduct",
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showContent: false,
    };
  },
  computed: {
    _coverages() {
      return this.product.price && this.product.price.coverages ? this.product.price.coverages : [];
    }
  },
  methods: {
    click() {
      if (this.product.hidePriceDetails) return;
      this.showContent = !this.showContent;
    }
  }
};
</script>
