<template>
  <div v-if="basket != null && basket.length > 0 && price" class="pricing-table">

    <!-- Products loop -->
    <price-table-product
      v-for="(item, index) in basket"
      :key="`product-${index}`"
      :product="item"
    />

    <!-- Extras loop -->
    <price-table-product
      v-for="(item, index) in _extras"
      :key="`extra-${index}`"
      :product="item"
    />

    <!-- Extra packages loop -->
    <price-table-product
      v-for="(item, index) in _extra_packages"
      :key="`extra-${index}`"
      :product="item"
    />

    <div class="pricing-table-footer" :class="{ 'without-discounts': !coupon && !collectDiscount }">
      <div v-if="(price.collectDiscount || price.voucherDiscount) && price.beforeDiscount && price.beforeDiscount.totalMonthlyPrice" class="pricing-table-footer-item">
        <span>I alt før rabat</span>
        <span>
          <span>{{ numFormat(price.beforeDiscount.totalMonthlyPrice) }}</span>
          <span class="pricing-table-product-info text-align-right">kr./md.</span>  
        </span>
      </div>
      <div v-if="coupon" class="pricing-table-footer-item discount">
        <span>
          <span><i class="fa-regular fa-tag icon fa-fw"></i> {{ coupon }}</span>
          <span v-if="price.voucherDiscount && price.voucherDiscount.totalMonthlyPriceDiscount" class="pricing-table-product-info">Årlig besparelse: {{ numFormat(price.voucherDiscount.totalMonthlyPriceDiscount*12) }} kr.</span>
        </span>
        <span>
          <span>
            <template v-if="price.voucherDiscount && price.voucherDiscount.totalMonthlyPriceDiscount">-{{ numFormat(price.voucherDiscount.totalMonthlyPriceDiscount) }}</template>
            <template v-else>0</template>
          </span>
          <span class="pricing-table-product-info text-align-right">kr./md.</span>
        </span>
      </div>
      <div v-if="collectDiscount" class="pricing-table-footer-item discount">
        <span>
          <span><i class="fa-regular fa-star icon fa-fw"></i> Helkunderabat</span>
          <span v-if="price.collectDiscount && price.collectDiscount.totalMonthlyPriceDiscount" class="pricing-table-product-info">Årlig besparelse: {{ numFormat(price.collectDiscount.totalMonthlyPriceDiscount*12) }} kr.</span>
        </span>
        <span>
          <span>
            <template v-if="price.collectDiscount && price.collectDiscount.totalMonthlyPriceDiscount">-{{ numFormat(price.collectDiscount.totalMonthlyPriceDiscount) }}</template>
            <template v-else>0</template>
          </span>
          <span class="pricing-table-product-info text-align-right">kr./md.</span>
        </span>
      </div>
      <div class="pricing-table-footer-item total-price">
        <span>I alt</span>
        <span>
          <span>{{ numFormat(price.totalMonthlyPrice) }}</span>
          <span class="pricing-table-product-info text-align-right">kr./md.</span>
        </span>
      </div>
    </div>
    <p v-if="garantifond && garantifond > 0 && !storm" class="font-size-12">
      Bemærk, at der ved første betaling i året opkræves {{ garantifond }} kr. som bidrag til <span class="clickable-text" v-on:click="showModal('Garantifond', 'Garantifond')">Garantifonden</span>.
    </p>
    <p v-if="garantifond && garantifond > 0 && storm" class="font-size-12">
      Bemærk, at der ved første betaling i året opkræves {{ garantifond }} kr. som bidrag til <span class="clickable-text" v-on:click="showModal('Garantifond', 'Garantifond')">Garantifonden</span> og {{ storm }} kr. i <span class="clickable-text" v-on:click="showModal('Naturskadeafgift', 'Naturskadeafgift')">naturskadeafgift</span>.
    </p>
  </div>
</template>

<script>
import PriceTableProduct from "@/components/price-table/price-table-product.vue";

export default {
  name: "PriceTable",
  components: {
    PriceTableProduct,
  },
  props: {
    basket: {
      type: Array,
      default: null,
    },
    price: {
      type: Object,
      default: null,
    },
    collectDiscount: {
      type: Boolean,
      default: false,
    },
    coupon: {
      type: String,
      default: null,
    },
  },
  computed: {
    _extra_packages() {
      let reduction = {};

      const r = this.basket.reduce((prev, val) => {
        for (const e of Object.entries(val.packages)) {
          const p = e[1];
          const sk = `${val.productCode}-${e[0]}`;
          const n = reduction[sk] || 0; 

          if (p.extra && !(p.singleton && n >= 1)) {
            prev.push(p);
            reduction[sk] = sk in reduction ? reduction[sk] + 1 :  1;
          }
        }

        return prev;
      }, []).map((val) => {
        return {
          basketName: val.title,
          price: val.price
        };
      });

      return r;
    },
    _extras() {      
      const r = this.basket.reduce((prev, val) => {
        if (val.extra) {
          for (const key in val.extra) {
            const extra = {...val.extra[key], price: val.extraPrices[key]};

            if (extra.chosen) {
              prev.push(extra);
            }
          }
        }

        return prev;
      }, []).map((val) => {
        return {
          basketName: val.value,
          price: val.price
        };
      });

      return r;
    },
    // The total value of garantifond
    storm() {
      // Amount to return
      let amount = 0;

      // Loop this.price
      if (this.price && this.price.objects) {
        this.price.objects.forEach((element) => {
          if (element.coverages) {
            element.coverages.forEach((coverage) => {
              if (coverage.bundleCode == 904 && coverage.price) {
                amount += coverage.price;
              }
            });
          }
        });
      }

      return amount;
    },
    // The total value of garantifond
    garantifond() {
      // Amount to return
      let amount = 0;

      // Loop this.price
      if (this.price && this.price.objects) {
        this.price.objects.forEach((element) => {
          if (element.coverages) {
            element.coverages.forEach((coverage) => {
              if (coverage.bundleCode == 920 && coverage.price) {
                amount += coverage.price;
              }
            });
          }
        });
      }

      return amount;
    },
  },
};
</script>
