<template>
  <div v-if="message" class="message" :class="[type, { floating: floating }]">
    <i v-if="messageIcon" class="message-icon" :class="messageIcon"></i>
    <div class="message-content">
      <span class="message-headline">{{ message.headline }}</span>
      <span v-if="message.text" class="message-text" v-html="message.text"></span>
      <div v-if="message.button" class="button-flex-container margin-top-20">
        <button type="button" class="button white justify-start" v-on:click="$emit('formFunction', message.button.function)">{{ message.button.text }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: {
    message: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "info",
    },
    icon: {
      type: String,
      default: null,
    },
    floating: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    messageIcon() {
      // If icon is provided, use this
      if (this.icon) {
        return this.icon;
      }
      // Otherwise use icon based on
      switch (this.type) {
        case "info":
          return "fa-solid fa-circle-info";
        case "warning":
          return "fa-solid fa-circle-exclamation";
        case "error":
          return "fa-solid fa-circle-xmark";
        case "success": 
          return "fa-solid fa-circle-check";
        default:
          return "fa-solid fa-circle-question";
      }
    },
  },
};
</script>
