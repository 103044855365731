<template>
  <div class="basket-product">

    <div class="basket-product-content">
      <div v-if="item.icon" class="basket-product-image" :style="{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundImage: `url(${item.icon})`
      }"></div>
      <img v-if="item.productIcon" :src="require(`@/assets/img/products/${item.productIcon}.svg`)" class="basket-product-image" />
      <div>
        <span class="basket-product-headline">{{ getProductName(item) }}</span>
        <span class="basket-product-price">
          <template v-if="item.price">
            {{ numFormat(item.price.totalMonthlyPrice) }} kr./md.
          </template>
        </span>
      </div>

      <div v-if="!item.hideDropDown" class="basket-product-dropdown" ref="dropdownMenu">
        <button class="basket-product-dropdown-button" @click="showDropdown = !showDropdown"></button>
        <div class="basket-product-dropdown-content" v-show="showDropdown">
          <a :href="item.productLink" target="_blank">
            <i class="fa-light fa-circle-info fa-fw"></i>
            <span>Læs mere på hjemmesiden</span>
          </a>
          <a href="#" @click.prevent="getConditions(item.productId, item.productName, _objectCode, item.conditionsLink)">
            <i v-if="!loading.conditions" class="fa-light fa-file-lines fa-fw"></i>
            <i v-else class="fa-light fa-spinner-third fa-fw icon-spin"></i>
            <span>Hent betingelser</span>
          </a>
          <a v-if="!item.hideFactSheet" href="#" @click.prevent="getFactScheme(item.productId, item.productName, _objectCode)">
            <i v-if="!loading.factScheme" class="fa-light fa-clipboard-check fa-fw"></i>
            <i v-else class="fa-light fa-spinner-third fa-fw icon-spin"></i>
            <span>Hent faktablad</span>
          </a>
        </div>
      </div>
    </div>

    <div class="basket-product-details" v-show="showDetails">
      <template v-for="(detail, dIdx) in item.details">
        <div class="detail-item" :key="'special-detail-item-' + dIdx">
          <span class="value">{{ detail | renderText(item.product) }}</span>
        </div>
      </template>
      <template v-for="(field, fieldIndex) in item.fields">
        <template v-if="field.details && field.value">
          <div class="detail-item" :key="'details-item-' + fieldIndex">
            <span class="label">
              <template v-if="field.shortLabel">
                {{ field.shortLabel }}
              </template>
              <template v-else>
                {{ field.label }}
              </template>
            </span>
            <span class="value">
              <template v-if="field.type == 'radio2'">{{ field.options.find(val => val.value == field.value).headline }}</template>
              <template v-else-if="field.selected && field.selected.value">{{ field.selected.value }}</template>
              <template v-else-if="!field.selected && field.filter">{{ $options.filters[field.filter](field.value) }}</template>
              <template v-else-if="!field.selected && field.detailFilter">{{ $options.filters[field.detailFilter](field.value) }}</template>
              <template v-else-if="field.value">{{ field.value }}</template>
            </span>
          </div>
        </template>
      </template>
      <template v-if="showPackageDetails(item)">
        <div class="detail-item">
          <span class="label">Pakke</span>
          <span class="value">{{ item.package.name }}</span>
        </div>
      </template>
      <template v-if="item.coverages">
        <template v-for="(coverage, coverageIndex) in item.coverages">
          <template v-if="showCoverageDetails(coverage)">
            <div class="detail-item" :key="coverageIndex">
              <span class="label">Ekstra dækning</span>
              <span class="value">{{ coverage.value }}</span>
            </div>
          </template>
        </template>
      </template>
      <template v-if="item.extra">
        <template v-for="(extra, i) in item.extra">
          <template v-if="extra.chosen">
            <div class="detail-item" :key="i">
              <span class="label">Tilkøbsforsikring</span>
              <span class="value">{{ extra.value }}</span>
            </div>
          </template>
        </template>
      </template>
    </div>

    <div class="basket-product-buttons">
      <button v-if="!item.noEdit || 'details' in item" class="button white basket-button" @click="showDetails = !showDetails">
        <i class="fa-light fa-chevron-down button-icon" :class="{ 'rotate180': showDetails }"></i>
        <span>
          <template v-if="showDetails">Skjul detaljer</template>
          <template v-else>Vis detaljer</template>
        </span>
      </button>
      <button v-if="!item.noEdit" class="button white basket-button" @click="edit(item, item.index)">
        <i class="fa-light fa-pen"></i>
        <span>Rediger</span>
      </button>
      <button v-if="!item.noDelete" class="button white basket-button" @click="del(item, item.index)">
        <i class="fa-light fa-trash-can"></i>
        <span>Fjern</span>
      </button>
    </div>
  </div>
</template>

<script>
import downloadDocuments from "@/mixins/downloadDocuments.js";

export default {
  name: "basket-product",
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  mixins: [downloadDocuments],
  data() {
    return {
      showDetails: false,
      showDropdown: false,
    };
  },
  computed: {
    _objectCode() {
      return this.item.objectCode || this.item.productId;
    }
  },
  mounted() {
    document.addEventListener("click", this.closeDropdown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    edit(item, index) {
      if (item.altEdit) {
        item.altEdit(item, index - 1);
      } else {
        this.$emit('editPolicy', item, index);
      }

      // Delete right after
      this.del(item, index);
    },
    del(item, index) {
      if (item.altDelete) {
        item.altDelete(item, index);
      } else {
        this.$emit('deletePolicy', index)
      }
    },
    showPackageDetails(item) {
      const r = "packages" in item && Object.keys(item.packages).length > 1 && item.package && (!("details" in item.package) || item.package.details);
      return r;
    },
    showCoverageDetails(coverage) {
      return coverage.chosen && (!("details" in coverage) || coverage.details);
    },
    /*
     * Close function
     */
    closeDropdown(event) {
      let el = this.$refs.dropdownMenu;
      if (el !== event.target && !el.contains(event.target)) {
        this.showDropdown = false;
      }
    },
  },
};
</script>
